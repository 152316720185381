import Experience from '../Experience.js'
import { gsap, CustomEase, CSSPlugin } from 'gsap/all'
import Split from '../Utils/Split.js'
import Error from './Error.js'
import artistsContent from '../artistsContent.js'

export default class Mobile
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.router = this.experience.router
        gsap.registerPlugin(CustomEase, CSSPlugin)
        CustomEase.create('easeOutCubic', '0.33, 1, 0.68, 1')
        CustomEase.create('easeInOutCubic', '0.65, 0, 0.35, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        this.loaded = false
        this.location = window.location.pathname
        this.loadOnArtist = false
        this.firstIntroDone = false
        this.isMenuOpen = false
        this.currentTab = 'about'
        this.bgY = 0
        this.clipPathInMenu = 100
        this.clipPathOutMenu = 0
        this.clipPathInTargetMenu = 100
        this.clipPathOutTargetMenu = 0
        this.opacityMenu = 0.0001
        this.textYLeft = 100
        this.textYLeft2 = 100
        this.textYBack = 100
        this.textTabsYMenu = -100
        this.textAboutYMenu = -100
        this.textCreditsYMenu = 100
        this.textWhite = 222
        this.textBlack = 0

        this.isHomeIntroStarted = false
        this.isHomeIntroDone = false
        this.nbOfImages = 14
        this.shuffledImageIndexArray = this.shuffledArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])
        
        // Nav
        document.querySelector('.mobile nav a').addEventListener('click', e =>
        {
            if(this.isArtist && !this.isMenuOpen)
            {
                e.preventDefault()
                history.pushState({}, '', '/')
                document.title = 'Gallery of fame'
            }
        })

        // Menu
        this.menuLinesSplitted = false

        document.querySelector('nav .menu-btn').addEventListener('click', () =>
        {
            if(this.isImageZoomStarted)
            {
                this.isImageZoomClose = true

                gsap.set('.mobile .artist', {
                    overflow: 'auto'
                })
            }
            else
            {
                this.isMenuOpen = !this.isMenuOpen

                if(this.isMenuOpen)
                {
                    this.clipPathInTargetMenu = 0
                    this.clipPathOutTargetMenu = 0
                }
                else 
                {
                    this.clipPathInTargetMenu = 0
                    this.clipPathOutTargetMenu = 100
                }
            }
        })

        document.querySelector('.menu .tabs p:first-of-type').addEventListener('click', () =>
        {
            this.currentTab = 'about'

            gsap.to('.menu .tabs p:first-of-type', {
                color: 'white'
            })

            gsap.to('.menu .tabs p:last-of-type', {
                color: 'rgba(85, 82, 82)'
            })
        })

        document.querySelector('.menu .tabs p:last-of-type').addEventListener('click', () =>
        {
            this.currentTab = 'credits'

            gsap.to('.menu .tabs p:first-of-type', {
                color: 'rgba(85, 82, 82)'
            })

            gsap.to('.menu .tabs p:last-of-type', {
                color: 'white'
            })
        })

        // Artist
        this.isArtist = false
        this.linesSplitted = false
        this.isArtistChanged = false
        this.isTransitionBetweenArtists = false
        this.isImageZoomStarted = false
        this.isImageZoomClose = false
        this.isImageZoomDone = false
        this.navTextY = 0
        this.closeY = 100
        this.closeRotate = 0
        this.zoomedImageOpacity = 0
        this.artistIndex = 0
        this.artistOpacity = 0
        this.artistOpacityValue = 0

        this.nbOfProjects = [
            3,
            4,
            4,
            4,
            3,
            3,
            3,
            3,
            4,
            3,
            4,
            3,
            3,
            3
        ]

        document.querySelectorAll('.mobile .home .imgs .img').forEach((image, index) =>
        {
            image.addEventListener('click', () => 
            {
                history.pushState({}, '', `/${artistsContent[13 - index].url}`)
                this.setArtistHtml(13 - index)
            })
        })

        document.querySelectorAll('.mobile .artist .projects .p').forEach((project, index) =>
        {
            project.addEventListener('click', () => 
            {
                document.querySelector('.mobile .artist .zoomed-img img').src = project.children[0].src

                gsap.set('.mobile .artist', {
                    overflow: 'hidden'
                })

                gsap.set('.mobile .artist .zoomed-img', {
                    display: 'flex',
                    top: document.querySelector('.mobile .artist').scrollTop
                })

                this.isImageZoomStarted = true
                this.isImageZoomDone = false
            })
        })

        document.querySelector('.mobile .artist .next').addEventListener('click', () =>
        {
            this.artistIndex === 13 ? this.artistIndex = 0 : this.artistIndex += 1

            history.pushState({}, '', `/${artistsContent[this.artistIndex].url}`)
            document.title = `${artistsContent[this.artistIndex].name}`
        })

        // 404
        this.isError = false
        this.errorTextY = 100

        // Load
        this.load = () => 
        {
            artistsContent.forEach((artist, index) => 
            {
                if(window.location.pathname === "/" + artist.url) 
                {
                    document.querySelector('.mobile .p404').remove()
                    this.artistIndex = index
                    this.loadOnArtist = true
                }
            })

            if(!this.loadOnArtist)
            {
                if(window.location.pathname === "/")
                {
                    document.querySelector('.mobile .p404').remove()
                }
                else
                {
                    document.querySelector('.mobile nav').remove()
                    document.querySelector('.mobile .menu').remove()
                    document.querySelector('.mobile .home').remove()
                    document.querySelector('.mobile .artist').remove()
                    this.error = new Error()
                    this.error.intro()
                }
            }

            this.loaded = true
        }

        // Orientation
        this.portrait = window.matchMedia("(orientation: portrait)").matches

        if(this.portrait) 
        {
            gsap.set('.landscape', {
                display: 'none'
            })
        } 
        else 
        {
            gsap.set('.landscape', {
                display: 'flex'
            })
        }

        window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
            this.portrait = e.matches

            if(this.portrait) 
            {
                gsap.set('.landscape', {
                    display: 'none'
                })
            } 
            else 
            {
                gsap.set('.landscape', {
                    display: 'flex'
                })
            }
        })

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.load()
        })
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    shuffledArray(array)
    {
        for(let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array
    }

    setArtistHtml(index)
    {
        this.linesSplitted = false

        document.querySelector('.artist .presentation .portrait').src = `./textures/Portrait/portrait-${index}.webp`
        document.querySelector('.artist .presentation .name').innerHTML = artistsContent[index].name
        document.querySelector('.artist .presentation .job').innerHTML = artistsContent[index].job
        document.querySelector('.artist .presentation .description').innerHTML = artistsContent[index].description
        document.querySelector('.artist .cover').src = `./textures/Cover/cover-${index}.webp`
        document.querySelector('.artist .second-description').innerHTML = artistsContent[index].secondDescription
        document.querySelector('.artist .projects .p-0 .p-img').src = `./textures/Projects/${index}/project-${index}-0.webp`
        document.querySelector('.artist .projects .p-0 .p-name').innerHTML = artistsContent[index].projectName0
        document.querySelector('.artist .projects .p-0 .p-date').innerHTML = artistsContent[index].projectDate0
        document.querySelector('.artist .projects .p-1 .p-img').src = `./textures/Projects/${index}/project-${index}-1.webp`
        document.querySelector('.artist .projects .p-1 .p-name').innerHTML = artistsContent[index].projectName1
        document.querySelector('.artist .projects .p-1 .p-date').innerHTML = artistsContent[index].projectDate1
        document.querySelector('.artist .projects .p-2 .p-img').src = `./textures/Projects/${index}/project-${index}-2.webp`
        document.querySelector('.artist .projects .p-2 .p-name').innerHTML = artistsContent[index].projectName2
        document.querySelector('.artist .projects .p-2 .p-date').innerHTML = artistsContent[index].projectDate2

        if(this.nbOfProjects[index] === 4)
        {
            document.querySelector('.artist .projects .p-3 .p-img').src = `./textures/Projects/${index}/project-${index}-3.webp`
            document.querySelector('.artist .projects .p-3 .p-name').innerHTML = artistsContent[index].projectName3
            document.querySelector('.artist .projects .p-3 .p-date').innerHTML = artistsContent[index].projectDate3
        }
        else
        {
            document.querySelector('.artist .projects .p-3 .p-img').src = ''
            document.querySelector('.artist .projects .p-3 .p-name').innerHTML = ''
            document.querySelector('.artist .projects .p-3 .p-date').innerHTML = ''
        }

        if(index === 13)
        {
            document.querySelector('.artist .next .next-img').src = './textures/Portrait/portrait-0.webp'
            document.querySelector('.artist .next .next-name').innerHTML = artistsContent[0].name
        }
        else
        {
            document.querySelector('.artist .next .next-img').src = `./textures/Portrait/portrait-${index + 1}.webp`
            document.querySelector('.artist .next .next-name').innerHTML = artistsContent[index + 1].name
        }

        document.title = `${artistsContent[index].name}`
    }

    homeIntro()
    {
        this.isHomeIntroStarted = true

        gsap.set('.mobile .home', {
            display: 'block'
        })

        for(let i = 0; i < this.nbOfImages; i++)
        {
            gsap.to(document.querySelectorAll('.mobile .home .img')[this.shuffledImageIndexArray[i]], {
                autoAlpha: 1,
                duration: .5,
                delay: i * .05,
                onComplete: () =>
                {
                    if(i === this.nbOfImages - 1)
                    {
                        this.isHomeIntroDone = true
                    }
                }
            })
        }
    }

    homeOutro()
    {
        for(let i = 0; i < this.nbOfImages; i++)
        {
            gsap.to(document.querySelectorAll('.mobile .home .img')[this.shuffledImageIndexArray[i]], {
                autoAlpha: 0.0001,
                duration: .5,
                delay: i * .05,
                onComplete: () => 
                {
                    if(i === this.nbOfImages - 1)
                    {
                        this.artistTransition()
                    }
                }
            })
        }
    }

    artistTransition()
    {
        this.isArtist = true

        gsap.set('.mobile .artist', {
            display: 'block'
        })
    }

    transitionBetweenArtists()
    {
        this.isArtistChanged = false
        this.isTransitionBetweenArtists = true
    }

    artistOutro()
    {
        this.isArtist = false
    }

    update()
    {
        if(this.loaded)
        {
            if(this.error)
            {
                this.error.update()
            }
            else 
            {
                if(window.location.pathname !== this.location)
                {
                    artistsContent.forEach((artist, index) => 
                    {
                        if(window.location.pathname === "/" + artist.url) 
                        {
                            if(this.location === "/")
                            {
                                this.artistIndex = index
                                this.setArtistHtml(index)
                                this.homeOutro()
                            }
                            else
                            {
                                this.artistIndex = index
                                this.transitionBetweenArtists()
                            }

                            return
                        } 
                        else if(index === artistsContent.length - 1)
                        {
                            if(window.location.pathname === '/') {
                                this.artistOutro()
                            }
                        }
                    })

                    this.location = window.location.pathname
                }

                if(!this.firstIntroDone)
                {
                    if(!this.loadOnArtist)
                    {
                        if(!this.isHomeIntroStarted)
                        {
                            this.homeIntro()
                            this.firstIntroDone = true
                        }
                    }
                    else
                    {
                        this.textYLeft = -100
                        if(!this.isArtist)
                        {
                            this.setArtistHtml(this.artistIndex)
                            this.artistTransition()
                            this.firstIntroDone = true
                        }
                    }
                }

                if(!this.isTransitionBetweenArtists)
                {
                    if(this.isArtist)
                    {
                        this.artistOpacity = this.lerp(this.artistOpacity, 1, .1)
                    }
                    else
                    {
                        this.artistOpacity = this.lerp(this.artistOpacity, 0, .1)

                        if(this.artistOpacity <= 0.001 && document.querySelector('.mobile .artist').style.display !== 'none')
                        {
                            document.querySelector('.mobile .artist').scrollTop = 0

                            gsap.set('.mobile .artist', {
                                display: 'none'
                            })

                            this.homeIntro()
                        }
                    }
                }
                else
                {
                    if(this.isArtistChanged)
                    {
                        this.artistOpacity = this.lerp(this.artistOpacity, 1, .1)

                        if(this.artistOpacity >= 0.99)
                        {
                            this.isTransitionBetweenArtists = false
                        }
                    }
                    else
                    {
                        this.artistOpacity = this.lerp(this.artistOpacity, 0, .1)

                        if(this.artistOpacity <= 0.001 && !this.isArtistChanged)
                        {
                            document.querySelector('.mobile .artist').scrollTop = 0

                            this.setArtistHtml(this.artistIndex)

                            this.isArtistChanged = true
                        }
                    }
                }

                if(!this.isImageZoomStarted || this.isImageZoomDone)
                {
                    if(this.isMenuOpen)
                    {
                        gsap.set('.menu', {
                            display: 'block'
                        })

                        this.opacityMenu = this.lerp(this.opacityMenu, 0, .0875)
                        this.bgY = this.lerp(this.bgY, 7.5, .0875)
                        this.closeRotate = this.lerp(this.closeRotate, -45, .0875)
                        this.textTabsYMenu = this.lerp(this.textTabsYMenu, 0, .0875)

                        if(this.currentTab === 'about')
                        {
                            this.textCreditsYMenu = this.lerp(this.textCreditsYMenu, 100, .0875)
                            
                            if(this.textCreditsYMenu >= 70)
                            {
                                this.textAboutYMenu = this.lerp(this.textAboutYMenu, 0, .0875)
                            }
                        }
                        else
                        {
                            this.textAboutYMenu = this.lerp(this.textAboutYMenu, -100, .0875)

                            if(this.textAboutYMenu <= -70)
                            {
                                this.textCreditsYMenu = this.lerp(this.textCreditsYMenu, 0, .0875)
                            }
                        }
                    }
                    else
                    {
                        this.opacityMenu = this.lerp(this.opacityMenu, 1, .0875)
                        this.bgY = this.lerp(this.bgY, 0, .0875)
                        this.closeRotate = this.lerp(this.closeRotate, 0, .0875)
                        this.textTabsYMenu = this.lerp(this.textTabsYMenu, -100, .0875)

                        if(this.currentTab === 'about')
                        {
                            this.textAboutYMenu = this.lerp(this.textAboutYMenu, -100, .0875)
                        }
                        else
                        {
                            this.textCreditsYMenu = this.lerp(this.textCreditsYMenu, -100, .0875)
                        }

                        if(this.opacityMenu >= .999)
                        {
                            this.clipPathInMenu = 100
                            this.clipPathOutMenu = 0
                            this.clipPathInTargetMenu = 100
                            this.clipPathOutTargetMenu = 0

                            gsap.set('.menu', {
                                display: 'none'
                            })
                        }
                    }

                    this.clipPathInMenu = this.lerp(this.clipPathInMenu, this.clipPathInTargetMenu, .0875)
                    this.clipPathOutMenu = this.lerp(this.clipPathOutMenu, this.clipPathOutTargetMenu, .0875)

                    gsap.set('.menu', {
                        clipPath: `inset(${this.clipPathOutMenu}% 0 ${this.clipPathInMenu}% 0)`
                    })

                    if(this.isArtist)
                    {
                        this.textYLeft = this.lerp(this.textYLeft, -100, .1)

                        gsap.set('.artist', {
                            opacity: this.opacityMenu,
                            y: `${this.bgY}%`,
                            force3D: true
                        })
                    }
                    else 
                    {
                        this.textYLeft = this.lerp(this.textYLeft, 0, .1)

                        gsap.set('.home', {
                            opacity: this.opacityMenu,
                            y: `${this.bgY}%`,
                            force3D: true
                        })
                    }

                    gsap.set('nav a span', {
                        y: `${this.textYLeft}%`,
                        force3D: true
                    })

                    this.closeY = this.lerp(this.closeY, 0, .1)

                    gsap.set('nav .menu-btn svg', {
                        y: `${this.closeY}%`,
                        force3D: true
                    })

                }
                else 
                {
                    if(!this.isImageZoomClose)
                    {
                        this.navTextY = this.lerp(this.navTextY, -200, .1)
                        gsap.set('nav a span', {
                            y: `${this.navTextY}%`,
                            force3D: true
                        })

                        this.closeRotate = this.lerp(this.closeRotate, -45, .1)

                        this.zoomedImageOpacity = this.lerp(this.zoomedImageOpacity, 1, .1)
                        gsap.set('.artist .zoomed-img', {
                            autoAlpha: this.zoomedImageOpacity
                        })
                    }
                    else
                    {
                        this.navTextY = this.lerp(this.navTextY, -100, .1)
                        gsap.set('nav a span', {
                            y: `${this.navTextY}%`,
                            force3D: true
                        })

                        this.closeRotate = this.lerp(this.closeRotate, 0, .1)

                        this.zoomedImageOpacity = this.lerp(this.zoomedImageOpacity, 0, .1)
                        gsap.set('.artist .zoomed-img', {
                            autoAlpha: this.zoomedImageOpacity
                        })

                        if(this.navTextY >= -101)
                        {
                            gsap.set('.mobile .artist .zoomed-img', {
                                display: 'none'
                            })

                            this.isImageZoomStarted = false
                            this.isImageZoomClose = false
                            this.isImageZoomDone = true
                        }
                    }
                }

                gsap.set('nav .menu-btn svg', {
                    rotate: `${this.closeRotate}%`,
                    force3D: true
                })

                if(!this.isMenuOpen)
                {
                    gsap.set('.mobile .artist', {
                        autoAlpha: this.artistOpacity
                    })
                }

                if(this.menuLinesSplitted)
                {
                    gsap.set('.menu .tabs .word', {
                        y: `${this.textTabsYMenu}%`,
                        force3D: true
                    })

                    gsap.set('.menu .column.about .word', {
                        y: `${this.textAboutYMenu}%`,
                        force3D: true
                    })

                    gsap.set('.menu .column.credits .word', {
                        y: `${this.textCreditsYMenu}%`,
                        force3D: true
                    })

                    gsap.set('.menu .copyrights .word', {
                        y: `${this.textTabsYMenu}%`,
                        force3D: true
                    })
                }


                if(document.querySelector('.mobile .menu .about').offsetTop !== 0 && !this.menuLinesSplitted)
                {
                    Split('lines', '.mobile .menu p')
                    Split('lines', '.mobile .menu a')
                    this.menuLinesSplitted = true
                }
            }
        }
    }
}